import i18next from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";


i18next 
.use(initReactI18next)
.use(LanguageDetector)
.init({
    resources: {
        en: {
            translation: {
                "live-streaming": "Live Streaming",
                "home": "Home",
                "organization": "Organization",
                "games": "Games",
                "tech": "Technology",
                "news": "News",
                "contact": "Contact",
                "our-games": "Our Games",
                "commercial-area": "Commercial Area",
                "live-high": "We are the first high-end Live Casino",
                "prod-high": "proudly produced in Latin America.",
                "our-tech": "Our Technology",
                "tech-text": "We have brought together an important team with vast experience in multiple disciplines, who have managed to obtain a high quality, stable, professional, striking and unique product. We combine great advances with a powerful control and administrative system, which allows the absolute control of the configurations, risks, balances in real time for our operators. We are a team of more than one hundred and fifty people who are highly committed to position ourselves in the preference of users at an international level, as well as our clients, who are important operators at a global level.",
                "about-us": "About Us",
                "about-us-text-one": "SprintGaming Casino is the first Spanish-speaking and Portuguese-language live casino development at the level of the largest developments worldwide.",
                "about-us-text-two": "Our company has been engaged in B2B service operations in the region for more than eight years, it has collected the feelings of hundreds of operators in all the countries of the region, which allows us to know the needs of the market and what the operators require. and end users.",
                "about-us-text-three": "The Spanish-speaking market is in full development, still far from reaching a sales peak, this allows operators to have clear rules for the safe development of their businesses, this becomes a highlight for this project We bring together all the elements of a first-line casino with innovative games, an excellent presence and a high-level production.",
                "catalogue": "Catalogue",
                "values": "Values",
                "investment": "Intelligent and Reliable Investment",
                "solution": "Sprint Gaming the best Live Casino solution",
                "sites": "in Spanish and Portuguese Language necessary on your site.",
                "vision": "Our Vision",
                "vision-text": "To be the undisputed leader in the live casino industry in Latin America, offering a unique and exciting experience for Spanish and Portuguese speaking players. We seek to be recognized as the destination of choice, providing innovation, quality and excellence in customer service, while promoting responsible gaming and setting the highest standards in the gaming industry in the region.",
                "products": "Products",
                "product-title": "We offer a diversity of live games for your platform",
                "potencial": "Why Sprint Gaming?",
                "potential":"Commercial Potential",
                "potencial-txt": "At Sprint Gaming we have many qualities for which you should choose us:",
                "potencial-txt-2": "Pioneering experience: We are Latin America's first live casino in Spanish and Portuguese, positioning us as a leader in the live gaming industry in the region.",
                "potencial-txt-3": "Authenticity and convenience: Our professional hosts speak your language, providing an authentic and immersive live casino experience where you feel comfortable.",
                "potencial-txt-4": "Variety of exciting games: Enjoy a wide selection of live games, such as bingo, roulette, blackjack, baccarat and many more designed to offer thrills and winning opportunities with every game.",
                "potencial-txt-5": "Innovation and quality: We keep our standards high by adopting cutting-edge technology and providing a secure, high-quality gaming platform.",
                "potencial-txt-6": "Exceptional customer service: Our customer service team is available 24 hours a day, 7 days a week to provide you with prompt, professional assistance, ensuring you maintain the best possible experience.",
                "potencial-txt-7": "Transparency and reliability: We pride ourselves on maintaining high standards of transparency, integrity and data protection, giving you confidence and security in every game.",
                "differentiators": "Differentiators",
                "differentiators-txt": "For our clients we installed a Spanish and Portuguese-speaking casino in our services, after a commercial and financial study the possibility of creating a live casino with these characteristics and we found the following differentiating values.",
                "technology": "Our Technology",
                "technology-txt":"We have made important technological alliances with companies of great importance in the field of live casino games, who now belong together with our company to this project, shielding all SprintGaming business options.",
                "showroom":"Customization of scenarios and games",
                "setroulette":"Roulette Set Roulette, Cards, Bingo",
                "trainingroom":"Training Room",
                "support":"State-of-the-art technology 24/7",
                "whatweoffer":"What we offer",
                "hispana":"First Spanish-speaking and Portuguese-language Casino",
                "unique":"Unique Games",
                "themes": "Unique Themes",
                "loyalty": "Bonuses and Customer Loyalty",
                "presenters":"Latin Presenters",
                "manager": "Country Product Manager with after sales service.",
                "discounts":"Promotions and Discounts to customers.",
                "fiestalatina":"Wheel of fortune game set with Latin American themes, with a digital multiplier.",
                "kingblackjack":"Black Jack table with different effects setting, it is a multiplayer game.",
                "caposroulette":"Automatic roulette, themed on legendary mafia characters without naming any in particular with random multipliers.",
                "caribbean": "Automatic roulette set in a Caribbean beach theme that has differentiating bonuses.",
                "caliente": "Automatic roulette, set in Mexican party themes with random multipliers.",
                "spaceroulette": "Automatic roulette, set in space themes.",
                "jurassicroulette": "Earn up to 150x on each roll.",
                "video": "Watch Video",
                "back": "Back",
                "about-img": "./images/titles/about_us.png",
                "about-title-left": "We work with the best suppliers in the world.",
                "services-img": "./images/titles/services_en.png",
                "read-img": "./images/services/btn-read-en.png",
                "services": "Services",
                "products-img": "./images/titles/products_en.png",
                "news-img": "./images/titles/news_en.png",
                "language": "language",
                "banner-title-1": "LEADERS OF E-GAMING IN LATIN AMERICA",
                "banner-title-2": "ENJOY THE RACES",
                "banner-title-3": "THE LARGEST POKER NETWORK",
                "banner-title-4": "LIVE BETTING, PREMATCH SPORT",
                "banner-title-5": "THE WORLD OF BETS CHANGED",
                "banner-title-6": "LIVE CASINO",
                "services-subtitle": "What we can do for you offering quality and results",
                "services-description": "We provide advice on everything related to the world of betting in Latin America and the world.",
                "know": "Learn More",
                "products-subtitle": "Our portfolio of products and services includes: sports betting, casino, live casino, poker, streaming games, virtual sports, esports, international horse racing and more. Whatever your need, whether it is a complete turnkey platform or an integration of a sports betting APP; We have multiple options to offer you the best in the market.",
                "news-subtitle": "We constantly update the news related to the world of betting",
                "about-subtitle": "Specialists in B2B services for the gaming industry.",
                "about-content": "We are a global provider of iGaming software solutions offering its partners a world-class casino and sports betting platform, available as an omnichannel online, mobile and retail solution. Through the use and integration of superior technology solutions and an agile development methodology, we offer creative, cost-effective and customized solutions.",
                "provider-title": "Introducing Our Allies",
                "we-are-ready": "We are ready to create your online betting site",
                "ready-description": "We help our clients succeed by creating brand identities, digital experiences, and printed materials that communicate clearly.",
                "news-title": "Find out about the newest",
                "provider-btn": "See All",
                "all-rights": "All rights reserved",
                "curacao-title": "Curaçao License",
                "curacao-subtitle": "We have established commercial, technical and legal alliances with master license operators of the government of Curaçao, for this reason we can offer sub licenses derived from these master licenses, thus giving legal coverage to the operations of our clients in different territories where they wish to operate using these licenses. We thus manage the intermediation to carry out these processes and obtain these sub-licenses at very affordable costs.",
                "Live-Casino": "Live Casino",
                "Live-Slots": "Live Slots",
                "Live-Betting": "Live Betting",
                "Apuestas-Prematch": "SportsBook",
                "Carreras-de-Caballo": "CenterHorses",
                "Poker-en-Vivo": "Live Poker",
                "Terminales": "Machine Services",
                "Loteria-en-Vivo": "Live Lottery",
                "Video-Bingo": "Video Bingo",
                "Spectrum": "Spectrum",
                "Datos-Deportivos": "Sport Data",
                "Versus": "Versus",
                "XchanceBet": "XchanceBet",
                "Subastas-Hipicas": "Horse Auction",
                "Pagos": "Payment",
                "Peleas-de-Gallos": "Cockfights",
                "Diseño-Corporativo": "Corporate Design",
                "Diseño-content": "We create and develop a brand style, we design the branding and all the elements that represent your company for an internal and external communication strategy to give the greatest scope and obtain financial goals.",
                "Marketing-Online": "Marketing Online",
                "Marketing-content": "We specialize in the management and sale through social networks, Ads campaigns, creation of web pages and digital marketing strategy.",
                "Racebook-Online": "Racebook Online",
                "Racebook-content": "Horse betting software, brings together hundreds of tracks from the best racing circuits in the world, all the tracks in the USA, Canada, the United Kingdom, Australia, Japan, Hong Kong and the most important classics of the United Arab Emirates, Dubai and the entire calendar international gala equestrian, the most complete coverage of Latin American horse racing, with races from Mexico, Panama, Ecuador, the Dominican Republic, Chile, Uruguay and Argentina.",
                "Asesoria-Legal": "Legal advice",
                "Asesoria-content": "Legal advice for our clients so that they can obtain all the legal requirements to upload their web pages and publish their promotions and sporting events.",
                "read-more": "Read more",
                "Happy-Clients": "Happy Clients",
                "Employees": "Employees",
                "Conference": "Conference",
                "Total-Users": "Total Users",
                "platform": "The Best Platform",
                "Design": "Design",
                "Development": "Development",
                "Application": "Application",
                "Marketing": "Marketing",
                "Knowledge":"Knowledge",
                "Knowledge-content":"We have the experience you need",
                "Working":"Working",
                "Working-content":"The best professionals in the area",
                "Solution":"Solution",
                "Solution-content":"Agents authorized to assist you",
                "Process":"Process",
                "Process-content":"We make you succeed",
                "Español": "Spanish",
                "English": "English",
                "Licence": "Licence",
                "Solutions": "Solutions",
                "Resources": "Resources",
                "footer-subtitle": "Over 2,000 new customers sign up around the world.",
                "stay-with-us": "Stay with us.",
                "Copyright":"Copyright All rights reserved",
                "Playlist-pt": "SprintGaming ShowCase",
                "Playlist-en": "SprintGaming ShowCase",
                "Playlist-es": "SprintGaming ShowCase",
                "Scope" : "Scope",
                "Markets" : "Markets",
                "Strategies" : "Strategies",
                "Games" : "Games",
                "Scaling" : "Scaling",
                "Technology" : "Technology",
                "Monetization" : "Monetization",
                "Investment" : "Investment",
                "Profits" : "Profits",
                "Multiplicadores": "Roulette wheels with multipliers X 1000",
                "Prime": "First Live Casino with player-to-player tournaments",
                "winners": "Latest Winners",
                "bets": "Latest Bets",
                "more-winners": "More winners are being added all the time. To locate the most recent winner's information",
                "INGRESAR": "Login",
                "All-games": "All Games",
                "scenarios": "Scenarios",
                "uniques": "Unique Themes",
                "techno": "Technology",
                "multiplicadores": "Multipliers",
                "high": "High",
                "transmision": "Transmission Quality",
                "powered": "Powerful",
                "admin": "Administrative System",
                "we-value": "We value all our players and understand the excitement of online gaming.",
                "phone": "Phone",
                "name": "name",
                "message": "message",
                "ENVIAR": "SEND",
                "contact-message":"Your message has been sent, we will be contacting you shortly.",
            }
        },
        es: {
            translation: {
                "live-streaming": "Señal en vivo",
                "home": "Inicio",
                "organization": "Organización",
                "games": "Juegos",
                "tech": "Tecnología",
                "news": "Noticias",
                "contact": "Contacto",
                "our-games": "Nuestros Juegos",
                "commercial-area": "Área comercial",
                "live-high": "Somos el primer Live Casino de alta gama",
                "prod-high": "producido con orgullo en LatinoAmerica.",
                "our-tech": "Nuestra tecnología",
                "tech-text": "Hemos conglomerado a un importante equipo humano con enorme experiencia en multiples disciplinas, quienes han logrado obtener un producto de altisima calidad, estable, profesional, llamativo y unico. Reunimos grande avances con un poderoso sistema de control y administrativo, que permite el control absoluto de las configuraciones, riesgos, balances en tiempo real para nuestros operadores. Somos un equipo de mas de ciento cincuenta personas que estan altamente comprometidos con posicionarnos en la preferencia de los usuarios a nivel internacional, como tambien de nuestros clientes, que son importantes operadores a nivel global.",
                "about-us": "Quienes Somos",
                "about-us-text-one": "El Casino SprintGaming es el primer desarrollo de live casino de habla hispana e idioma portugués a la altura de los más grandes desarrollos a nivel mundial.",
                "about-us-text-two": "Nuestra empresa se dedica hace más de ocho años en operaciones de servicios B2B en la región, ha recogido el sentir de cientos de operadores en todos los países de la región, lo que nos permite conocer las necesidades del mercado y saber lo que requieren los operadores y usuarios finales.",
                "about-us-text-three": ">El mercado de habla hispana se encuentra en pleno desarrollo aún lejos de alcanzar un tope de ventas, esto permite a los operadores tener reglas claras para el desarrollo seguro de sus negocios, esto se convierte en un punto a destacar para este proyecto Reunimos todos los elementos de un casino de primera línea con innovadores juegos, excelente presencia y una producción de alto nivel.",
                "values": "Valores",
                "solution": "Sprint Gaming la mejor solución de Casino en Vivo",
                "sites": "en Idioma Español y Portugues necesaria en tu sitio.",
                "vision": "Nuestra Vision",
                "vision-text": "Ser el líder indiscutible en la industria del live casino en Latinoamérica, ofreciendo una experiencia única y emocionante para jugadores de habla hispana y portuguesa. Buscamos ser reconocidos como el destino preferido, brindando innovación, calidad y excelencia en el servicio al cliente, mientras promovemos el juego responsable y establecemos estándares más altos en la industria del juego en la región.",
                "products": "Productos",
                "product-title": "Ofrecemos una diversidad de juegos en vivo para tu plataforma.",
                "potencial": "Porqué Sprint Gaming?",
                "potential":"Potencial Comercial",
                "potencial-txt": "En Sprint Gaming tenemos muchas cualidades por las cuales nos deberian de elegir:",
                "potencial-txt-2": "Experiencia pionera: Somos el primer casino en vivo de Latinoamérica en español y portugués, lo que nos posiciona como líderes en la industria del juego en vivo en la región.",
                "potencial-txt-3": "Autenticidad y conveniencia: Nuestras presentadoras profesionales hablan tu idioma, brindando una experiencia de casino en vivo auténtica y envolvente desde donde te sientas cómodo.",
                "potencial-txt-4": "Variedad de juegos emocionantes: Disfruta de una amplia selección de juegos en vivo, como bingo, ruleta, blackjack, baccarat y muchos más diseñados para ofrecer emociones y oportunidades de ganar en cada partida.",
                "potencial-txt-5": "Innovación y calidad: Mantenemos nuestros estándares altos al adoptar tecnología de vanguardia y brindar una plataforma de juego segura y de alta calidad.",
                "potencial-txt-6": "Atención al cliente excepcional: Nuestro equipo de servicio al cliente está disponible las 24 horas, los 7 días de la semana, para brindarte asistencia profesional rápida, asegurando mantener la mejor experiencia posible.",
                "potencial-txt-7": "Transparencia y confiabilidad: Nos enorgullece mantener altos estándares de transparencia, integridad y protección de datos, brindándote confianza y seguridad en cada juego.",
                "differentiators": "Diferenciadores",
                "differentiators-txt": "Por nuestros clientes instalamos un casino de habla hispana y portugués en nuestros servicios, luego a un estudio comercial y financiero la posibilidad de la creación de un casino en vivo con estas características y encontramos los siguientes valores diferenciadores.",
                "technology": "Nuestra Tecnología",
                "technology-txt":"Hemos realizado importantes alianzas tecnológicas con empresas de gran importancia en el ámbito de los juegos de casino en vivo, quienes ahora pertenecen junto a nuestra empresa a este proyecto, blindando todas las opciones de negocio de SprintGaming.",
                "showroom":"Personalización  de escenarios y juegos",
                "setroulette":"Set de Ruleta Roulette, Cartas, Bingo",
                "trainingroom":"Salon de Entrenamiento y Capacitación",
                "support":"Tecnologia de punta 24/7 y Equipos",
                "whatweoffer":"Que Ofrecemos",
                "hispana":"Primer Casino de Habla Hispana e Idioma Portugues",
                "unique":"Juegos Únicos",
                "themes": "Temáticas Únicas",
                "loyalty": "Bonos y Fidelización a Clientes",
                "presenters":"Presentadoras Latinas",
                "manager": "Country Mánager de producto con servicio pos venta.",
                "discounts":"Promociones y Descuentos a clientes.",
                "fiestalatina":"Juego de Rueda de la fortuna ambientado con temáticas de latinoamérica, con multiplicador digital.",
                "kingblackjack":"Mesa de Black Jack con ambientación de efectos diversos, es un juego multijugador.",
                "caposroulette":"Ruleta automática, ambientada en temas de personajes de mafia legendarios sin nombrar ninguno en especial con multiplicadores aleatorios.",
                "caribbean": "Ruleta automática ambientada en temática playera del caribe que cuenta con bonos diferenciadores.",
                "caliente": "Ruleta automática, ambientada en temas de fiestas mexicanas con multiplicadores aleatorios",
                "spaceroulette": "Ruleta automática, ambientada en temas de espaciales.",
                "jurassicroulette": "Gana hasta 150x en cada tirada.",
                "video": "Ver Video",
                "back": "Atrás",
                "about-img": "./images/titles/btn-about.png",
                "about-title-left": "Trabajamos con los mejores proveedores del mundo.",
                "services-img": "./images/titles/btn-services.png",
                "read-img": "./images/services/btn-read.png",
                "services": "Servicios",
                "products-img": "./images/titles/btn-products.png",
                "news-img": "./images/titles/btn-news.png",
                "catalogue": "Catálogo",
                "language": "Idioma",
                "banner-title-1": "LÍDERES DEL E-GAMING EN LATINOAMERICA",
                "banner-title-2": "DISFRUTE DE LAS CARRERAS",
                "banner-title-3": "LA RED MÁS GRANDE DE POKER",
                "banner-title-4": "APUESTAS EN VIVO, APUESTAS PREMATCH",
                "banner-title-5": "EL MUNDO DE LAS APUESTAS CAMBIO",
                "banner-title-6": "CASINO EN VIVO",
                "services-subtitle": "Lo que podemos hacer por usted ofreciendo calidad y resultados",
                "services-description": "Brindamos asesoria de todo lo relacionado al mundo de las apuestas en america latina y el mundo.",
                "know": "Conoce Más",
                "products-title": "Nuestros Productos",
                "products-subtitle": "Nuestra cartera de productos y servicios incluye: apuestas deportivas, casino, casino en vivo, póquer, juegos en streaming, deportes virtuales, deportes electrónicos,  carreras de caballos internacionales y más. Independientemente cual sea su necesidad, bien sea una plataforma completa, llave en mano o una integración de una APP de apuestas deportivas; tenemos multiples opciones para ofrecerles lo mejor del mercado..",
                "news-subtitle": "Actualizamos de forma constante las noticias relacionadas al mundo de las apuestas",
                "about-subtitle": "Especialistas en servicios B2B para la industria del gaming.",
                "about-content": "Somos proveedor global de soluciones de software de iGaming que ofrece a sus socios una plataforma de apuestas deportivas y casinos de clase mundial, está disponible como una solución omnicanal en línea, móvil y minorista. Mediante el uso y la integración de soluciones tecnológicas superiores y una metodología de desarrollo ágil, ofrecemos soluciones creativas, rentables y personalizadas.",
                "provider-title": "Presentamos a Nuestros Aliados",
                "we-are-ready": "Estamos listos para crear tu sitio de apuestas online",
                "ready-description": "Ayudamos a nuestros clientes a tener éxito mediante la creación de identidades de marca, experiencias digitales y materiales impresos que se comunican con claridad.",
                "news-title": "Enterate de los más nuevo",
                "provider-btn": "Ver todos",
                "all-rights": "Todos los derechos reservados",
                "curacao-title": "Licencia Curazao",
                "curacao-subtitle": "Hemos establecido alianzas comerciales, técnicas y jurídicas con operadores master de licencia del gobierno de curazao, por ello podemos ofrecer sub licencias derivadas de estas licencias master, dando así cobertura legal a las operaciones de nuestros clientes en diferentes territorios donde deseen operar utilizando estas licencias, gestionamos así la intermediación para llevar estos procesos y obtener estas sub licencias a costos muy accesibles.",
                "Live-Casino": "Casino en Vivo",
                "Live-Slots": "Slots",
                "Live-Betting": "Apuestas en Vivo",
                "Apuestas-Prematch": "Apuestas Prematch",
                "Carreras-de-Caballo": "Caballos",
                "Poker-en-Vivo": "Poker en Vivo",
                "Terminales": "Terminales",
                "Loteria-en-Vivo": "Loteria en Vivo",
                "Video-Bingo": "Video Bingo",
                "Spectrum": "Spectrum",
                "Datos-Deportivos": "Datos Deportivos",
                "Versus": "Versus",
                "XchanceBet": "XchanceBet",
                "Subastas-Hipicas": "Subastas Hípicas",
                "Pagos": "Pagos",
                "Peleas-de-Gallos": "Peleas de Gallos",
                "Diseño-Corporativo": "Diseño Corporativo",
                "Diseño-content": "Creamos y desarrollamos un estilo de marca, diseñamos el branding  y todos los elementos que representan su empresa para una estrategía de comunicación interna y externa para dar el mayor alcance y obtención de las metas financieras.",
                "Marketing-Online": "Marketing Online",
                "Marketing-content": "Nos especializamos en la gestión y venta por medio de redes sociales, campañas Ads, creación de páginas web y estrategia de mercadeo digitales.",
                "Racebook-Online": "Racebook Online",
                "Racebook-content": "Software de apuestas hípicas, reúne cientos de pistas de los mejores circuitos de carreras del mundo, todas las pistas de USA, Canadá, Reino Unido, Australia, Japón, Hong Kong y los más importantes clásicos de los Emiratos Árabes, Dubái y todo el calendario hípico de gala internacional, cobertura más completa del hipismo latinoamericano, con carreras desde México, Panamá, Ecuador, Rep. Dominicana, Chile, Uruguay y Argentina.",
                "Asesoria-Legal": "Asesoria Legal",
                "Asesoria-content": "Asesoria Legal para nuestros clientes a fin de que puedan obtener todos los requisitos legales para subir sus páginas web y publicar sus promociones y eventos deportivos.",
                "read-more": "Leer más",
                "Happy-Clients": "Clientes Felices",
                "Employees": "Empleados",
                "Conference": "Conferencias",
                "Total-Users": "Total Usuarios",
                "platform": "La Mejor Plataforma",
                "Design": "Diseño",
                "Development": "Desarrollo",
                "Application": "Aplicación",
                "Marketing": "Marketing",
                "Knowledge":"Conocimiento",
                "Knowledge-content":"Tenemos la experiencia que necesita",
                "Working":"Trabajo",
                "Working-content":"Los mejores profesionales del área",
                "Solution":"Soluciones",
                "Solution-content":"Agentes autorizados para atenderlos",
                "Process":"Proceso",
                "Process-content":"Hacemos que llegues al éxito",
                "Español": "Español",
                "English": "Ingles",
                "Licence": "Licencia",
                "Solutions": "Soluciones",
                "Resources": "Recursos",
                "footer-subtitle": "Más de 2000 de nuevos clientes se suscriben alrededor del mundo.",
                "stay-with-us": "Quedate con Nosotros.",
                "Copyright":"Copyright Todos los Derechos Reservados",
                "Playlist-pt": "SprintGaming ShowCase",
                "Playlist-en": "SprintGaming ShowCase",
                "Playlist-es": "SprintGaming ShowCase",
                "Scope" : "Alcance",
                "Markets" : "Mercados",
                "Strategies" : "Estrategias",
                "Games" : "Juegos",
                "Scaling" : "Escalamiento",
                "Technology" : "Tecnología",
                "Monetization" : "Monetización",
                "Investment" : "Inversión",
                "Profits" : "Ganancias",
                "Multiplicadores": "Ruletas con multiplicadores X 1000",
                "Prime": "Primer Live Casino con torneos entre jugadores",
                "winners": "Ultimos Ganadores",
                "bets": "Últimas Apuestas",
                "more-winners": "Cada vez se añaden más ganadores. Para localizar la información del ganador más reciente",
                "INGRESAR": "INGRESAR",
                "All-games": "Todos los Juegos",
                "scenarios": "Escenarios",
                "uniques": "Temáticas Únicas",
                "techno": "Tecnología",
                "multiplicadores": "de Multiplicadores",
                "high": "Alta",
                "transmision": "Calidad de Transmisión",
                "powered": "Poderoso",
                "admin": "Sistema Administrativo",
                "we-value": "Valoramos a todos nuestros jugadores y comprendemos la emoción del juego en línea.",
                "phone": "Teléfono",
                "name": "Nombres",
                "message": "Mensaje",
                "ENVIAR": "Enviar",
                "contact-message":"Su mensaje ha sido enviado, estaremos contactandolo próximamente.",
            }
        },
        pt: {
            translation: {
                "live-streaming": "Transmissão ao vivo",
                "home": "Começo",
                "about-us": "Sobre Nós",
                "organization": "Organização",
                "our-tech": "Nossa tecnologia",
                "games": "Jogos",
                "tech": "Tecnologia",
                "news": "Notícias",
                "contact": "Contacto",
                "our-games": "Nossos jogos",
                "commercial-area": "Área comercial",
                "live-high": "Somos o primeiro ao vivo de alto nível",
                "prod-high": "produzido com orgulho na América Latina.",
                "tech-text": "Reunimos uma equipe importante com enorme experiência em várias disciplinas, que conseguiu obter um produto da mais alta qualidade, estável, profissional, atraente e exclusivo. Combinamos grandes avanços com um poderoso sistema de controle e administração, que permite o controle absoluto das configurações, dos riscos e dos saldos em tempo real para nossos operadores. Somos uma equipe de mais de cento e cinquenta pessoas altamente comprometidas em nos posicionar na preferência dos usuários internacionalmente, bem como de nossos clientes, que são importantes operadores em todo o mundo.",
                "about-us-text-one": "O SprintGaming Casino é o primeiro desenvolvimento de cassino ao vivo em espanhol e português no nível dos maiores desenvolvimentos em todo o mundo.",
                "about-us-text-two": "Nossa empresa está envolvida em operações de serviços B2B na região há mais de oito anos, coletando os sentimentos de centenas de operadoras em todos os países da região, o que nos permite conhecer as necessidades do mercado e o que as operadoras exigem .e usuários finais.",
                "about-us-text-three": "O mercado de língua espanhola está em pleno desenvolvimento, ainda longe de atingir um pico de vendas, isso permite que as operadoras tenham regras claras para o desenvolvimento seguro de seus negócios, isso se torna um destaque para este projeto Reunimos todos os elementos de um primeiro- casino online com jogos inovadores, uma excelente presença e uma produção de alto nível.",
                "values": "Valores",
                "solution": "Sprint Gaming a melhor solução de Live Casino",
                "sites": "em Espanhol e Português Idioma necessário em seu site.",
                "vision": "Nossa visão",
                "vision-text": "Ser o líder incontestável no setor de cassino ao vivo na América Latina, oferecendo uma experiência única e emocionante para jogadores de língua espanhola e portuguesa. Buscamos ser reconhecidos como o destino preferido, oferecendo inovação, qualidade e excelência no atendimento ao cliente, ao mesmo tempo em que promovemos o jogo responsável e estabelecemos os mais altos padrões no setor de jogos na região.",
                "products": "Productos",
                "product-title": "Oferecemos uma diversidade de jogos ao vivo para sua plataforma.",
                "potencial": "Por que jogar Sprint?",
                "potential":"Potencial Comercial",
                "potencial-txt": "Na Sprint Gaming, temos muitas qualidades pelas quais você deve nos escolher:",
                "potencial-txt-2": "Experiência pioneira: Somos o primeiro cassino ao vivo da América Latina em espanhol e português, o que nos posiciona como líderes no setor de jogos ao vivo na região.",
                "potencial-txt-3": "Autenticidade e conveniência: Nossos apresentadores profissionais falam o seu idioma, proporcionando uma experiência de cassino ao vivo autêntica e envolvente, onde você se sente confortável.",
                "potencial-txt-4": "Variedade de jogos emocionantes: Desfrute de uma ampla seleção de jogos ao vivo, incluindo bingo, roleta, blackjack, bacará e muitos outros, projetados para proporcionar emoções e oportunidades de vitória em cada jogo.",
                "potencial-txt-5": "Inovação e qualidade: Mantemos nossos padrões elevados ao adotar tecnologia de ponta e fornecer uma plataforma de jogos segura e de alta qualidade.",
                "potencial-txt-6": "Atendimento ao cliente excepcional: nossa equipe de atendimento ao cliente está disponível 24 horas por dia, 7 dias por semana, para fornecer assistência rápida e profissional, garantindo a melhor experiência possível.",
                "potencial-txt-7": "Transparência e confiabilidade: temos orgulho de manter altos padrões de transparência, integridade e proteção de dados, proporcionando confiança e segurança em todos os jogos.",
                "differentiators": "Diferenciadores",
                "differentiators-txt": "Para os nossos clientes instalámos nos nossos serviços um casino de língua espanhola e portuguesa, após um estudo comercial e financeiro a possibilidade de criar um casino ao vivo com estas características e encontrámos os seguintes valores diferenciadores.",
                "technology": "Nossa Tecnologia",
                "technology-txt":"Fizemos importantes alianças tecnológicas com empresas de grande relevância no campo dos jogos de casino ao vivo, que agora pertencem juntamente com a nossa empresa a este projeto, blindando todas as opções de negócio da SprintGaming.",
                "showroom":"Customização de cenários e jogos",
                "setroulette":"Conjunto de Roleta Roleta, Cartões, Bingo",
                "trainingroom":"Sala de treino",
                "support":"Tecnologia de ponta 24/7",
                "whatweoffer":"Que oferecemos",
                "hispana":"Primeiro cassino de língua espanhola e portuguesa",
                "unique":"Jogos exclusivos",
                "themes": "Temas exclusivos",
                "loyalty": "Bônus e Fidelização de Clientes",
                "presenters":"Apresentadores latinos",
                "manager": "Country Product Manager com serviço pós-venda.",
                "discounts":"Promoções e descontos para clientes.",
                "fiestalatina":"Jogo roda da fortuna ambientado com temas latino-americanos, com multiplicador digital.",
                "kingblackjack":"Mesa Black Jack com diferentes configurações de efeitos, é um jogo multiplayer.",
                "caposroulette":"Roleta automática, temática de personagens lendários da máfia sem nomear nenhum em particular com multiplicadores aleatórios.",
                "caribbean": "Roleta automática ambientada em uma praia do Caribe com bônus diferenciadores.",
                "caliente": "Roleta automática, ambientada em temas de festas mexicanas com multiplicadores aleatórios",
                "spaceroulette": "Roleta automática, com um tema espacial.",
                "jurassicroulette": "Ganhe até 150x em cada rodada.",
                "video": "Ver Video",
                "back": "Atrás",
                "about-img": "./images/titles/btn-about.png",
                "about-title-left": "Trabajamos con los mejores proveedores del mundo.",
                "services-img": "./images/titles/btn-services.png",
                "read-img": "./images/services/btn-read.png",
                "services": "Servicios",
                "products-img": "./images/titles/btn-products.png",                
                "news-img": "./images/titles/btn-news.png",
                "catalogue": "Catálogo",
                "language": "Idioma",
                "banner-title-1": "LÍDERES DEL E-GAMING EN LATINOAMERICA",
                "banner-title-2": "DISFRUTE DE LAS CARRERAS",
                "banner-title-3": "LA RED MÁS GRANDE DE POKER",
                "banner-title-4": "APUESTAS EN VIVO, APUESTAS PREMATCH",
                "banner-title-5": "EL MUNDO DE LAS APUESTAS CAMBIO",
                "banner-title-6": "CASINO EN VIVO",
                "services-subtitle": "Lo que podemos hacer por usted ofreciendo calidad y resultados",
                "services-description": "Brindamos asesoria de todo lo relacionado al mundo de las apuestas en america latina y el mundo.",
                "know": "Conoce Más",
                "products-title": "Nuestros Productos",
                "products-subtitle": "Nuestra cartera de productos y servicios incluye: apuestas deportivas, casino, casino en vivo, póquer, juegos en streaming, deportes virtuales, deportes electrónicos,  carreras de caballos internacionales y más. Independientemente cual sea su necesidad, bien sea una plataforma completa, llave en mano o una integración de una APP de apuestas deportivas; tenemos multiples opciones para ofrecerles lo mejor del mercado..",
                "news-subtitle": "Actualizamos de forma constante las noticias relacionadas al mundo de las apuestas",
                "about-subtitle": "Especialistas en servicios B2B para la industria del gaming.",
                "about-content": "Somos proveedor global de soluciones de software de iGaming que ofrece a sus socios una plataforma de apuestas deportivas y casinos de clase mundial, está disponible como una solución omnicanal en línea, móvil y minorista. Mediante el uso y la integración de soluciones tecnológicas superiores y una metodología de desarrollo ágil, ofrecemos soluciones creativas, rentables y personalizadas.",
                "provider-title": "Presentamos a Nuestros Aliados",
                "we-are-ready": "Estamos listos para crear tu sitio de apuestas online",
                "ready-description": "Ayudamos a nuestros clientes a tener éxito mediante la creación de identidades de marca, experiencias digitales y materiales impresos que se comunican con claridad.",
                "news-title": "Enterate de los más nuevo",
                "provider-btn": "Ver todos",
                "all-rights": "Todos los derechos reservados",
                "curacao-title": "Licencia Curazao",
                "curacao-subtitle": "Hemos establecido alianzas comerciales, técnicas y jurídicas con operadores master de licencia del gobierno de curazao, por ello podemos ofrecer sub licencias derivadas de estas licencias master, dando así cobertura legal a las operaciones de nuestros clientes en diferentes territorios donde deseen operar utilizando estas licencias, gestionamos así la intermediación para llevar estos procesos y obtener estas sub licencias a costos muy accesibles.",
                "Live-Casino": "Casino en Vivo",
                "Live-Slots": "Slots",
                "Live-Betting": "Apuestas en Vivo",
                "Apuestas-Prematch": "Apuestas Prematch",
                "Carreras-de-Caballo": "Caballos",
                "Poker-en-Vivo": "Poker en Vivo",
                "Terminales": "Terminales",
                "Loteria-en-Vivo": "Loteria en Vivo",
                "Video-Bingo": "Video Bingo",
                "Spectrum": "Spectrum",
                "Datos-Deportivos": "Datos Deportivos",
                "Versus": "Versus",
                "XchanceBet": "XchanceBet",
                "Subastas-Hipicas": "Subastas Hípicas",
                "Pagos": "Pagos",
                "Peleas-de-Gallos": "Peleas de Gallos",
                "Diseño-Corporativo": "Diseño Corporativo",
                "Diseño-content": "Creamos y desarrollamos un estilo de marca, diseñamos el branding  y todos los elementos que representan su empresa para una estrategía de comunicación interna y externa para dar el mayor alcance y obtención de las metas financieras.",
                "Marketing-Online": "Marketing Online",
                "Marketing-content": "Nos especializamos en la gestión y venta por medio de redes sociales, campañas Ads, creación de páginas web y estrategia de mercadeo digitales.",
                "Racebook-Online": "Racebook Online",
                "Racebook-content": "Software de apuestas hípicas, reúne cientos de pistas de los mejores circuitos de carreras del mundo, todas las pistas de USA, Canadá, Reino Unido, Australia, Japón, Hong Kong y los más importantes clásicos de los Emiratos Árabes, Dubái y todo el calendario hípico de gala internacional, cobertura más completa del hipismo latinoamericano, con carreras desde México, Panamá, Ecuador, Rep. Dominicana, Chile, Uruguay y Argentina.",
                "Asesoria-Legal": "Asesoria Legal",
                "Asesoria-content": "Asesoria Legal para nuestros clientes a fin de que puedan obtener todos los requisitos legales para subir sus páginas web y publicar sus promociones y eventos deportivos.",
                "read-more": "Leer más",
                "Happy-Clients": "Clientes Felices",
                "Employees": "Empleados",
                "Conference": "Conferencias",
                "Total-Users": "Total Usuarios",
                "platform": "La Mejor Plataforma",
                "Design": "Diseño",
                "Development": "Desarrollo",
                "Application": "Aplicación",
                "Marketing": "Marketing",
                "Knowledge":"Conocimiento",
                "Knowledge-content":"Tenemos la experiencia que necesita",
                "Working":"Trabajo",
                "Working-content":"Los mejores profesionales del área",
                "Solution":"Soluciones",
                "Solution-content":"Agentes autorizados para atenderlos",
                "Process":"Proceso",
                "Process-content":"Hacemos que llegues al éxito",
                "Español": "Español",
                "English": "Ingles",
                "Licence": "Licencia",
                "Solutions": "Soluciones",
                "Resources": "Recursos",
                "footer-subtitle": "Más de 2000 de nuevos clientes se suscriben alrededor del mundo.",
                "stay-with-us": "Quedate con Nosotros.",
                "Copyright":"Copyright Todos los Derechos Reservados",
                "Playlist-pt": "SprintGaming ShowCase",
                "Playlist-en": "SprintGaming ShowCase",
                "Playlist-es": "SprintGaming ShowCase",
                "Scope" : "Escopo",
                "Markets" : "Mercados",
                "Strategies" : "Estratégias",
                "Games" : "Jogos",
                "Scaling" : "Escala",
                "Technology" : "Tecnología",
                "Monetization" : "Monetização",
                "Investment" : "Investimento",
                "Profits" : "Lucros",
                "Multiplicadores": "Roletas com multiplicadores X 1000",
                "Prime": "Primeiro cassino ao vivo com torneios entre jogadores",
                "winners": "Últimos vencedores",
                "bets": "Apostas mais recentes",
                "more-winners": "Mais vencedores estão sendo adicionados o tempo todo. Para encontrar as informações do último vencedor",
                "INGRESAR": "Login",
                "All-games": "Todos os jogos",
                "scenarios": "Cenários",
                "uniques": "Temáticas Únicas",
                "techno": "Tecnologia",
                "multiplicadores": "de Multiplicadores",
                "high": "Alta",
                "transmision": "Qualidade da transmissão",
                "powered": "Poderoso",
                "admin": "Sistema Administrativo",
                "we-value": "Valorizamos todos os nossos jogadores e entendemos a empolgação dos jogos on-line.",
                "phone": "Telefone",
                "name": "Nombres",
                "message": "Mensagem",
                "ENVIAR": "Enviar",
                "contact-message":"Sua mensagem foi enviada, entraremos em contato em breve.",
            }
        }
    },
    lng: "es",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false
    }
})
