import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'
import './utils/i18n';

// Pages import Here 
import HomeDefault from "./pages/HomeDefault";
import Flipbook from "./pages/Flipbook";

// Import Css Here 
// import './assets/scss/style.scss';
// Import Css Custom 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './assets/css/bootstrap.min.css';
import './assets/css/main.css';
import './assets/css/custom.css';
import './assets/js/main.js';
import './assets/js/fontawesome.js';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons'

library.add(fab, fas, faCheckSquare, faCoffee)
const App = () => {

    return (
        <Router>
            <PageScrollTop>
                <Switch>
                    <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={HomeDefault}/>
                    <Route path={`${process.env.PUBLIC_URL + "/flipbook"}`} exact component={Flipbook}/>
                    <Route path={`${process.env.PUBLIC_URL + "/error"}`} exact component={Error}/>

                </Switch>
            </PageScrollTop>
        </Router>
    )
}


export default App
