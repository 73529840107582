import { React, useState } from "react";
import {Link} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {useTranslation} from "react-i18next";
import Slider from "react-slick";
import Dropdown from 'react-bootstrap/Dropdown';

const BusinessConsulting2 = () => {
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [show5, setShow5] = useState(false);
    const [show6, setShow6] = useState(false);
    const [show7, setShow7] = useState(false);
    const [show8, setShow8] = useState(false);
    const [show9, setShow9] = useState(false);
    const [show10, setShow10] = useState(false);
    const [show11, setShow11] = useState(false);
    const [showCase, setShowCase] = useState(false);
    const [showLink, setShowLink] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [showPassConfirm, setShowPassConfirm] = useState("sptgming20!%");
    const [name, setName] = useState();
    const [password, setPassword] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [message, setMessage] = useState();
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const handleShow = () => setShow(true);
    const handleShow1 = () => setShow1(true);
    const handleShow2 = () => setShow2(true);
    const handleShow3 = () => setShow3(true);
    const handleShow4 = () => setShow4(true);
    const handleShow5 = () => setShow5(true);
    const handleShow6 = () => setShow6(true);
    const handleShow7 = () => setShow7(true);
    const handleShow8 = () => setShow8(true);
    const handleShow9 = () => setShow9(true);
    const handleShow10 = () => setShow10(true);
    const handleShow11 = () => setShow11(true);
    
    
    const {t, i18n} = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    const [isOpen, setOpen] = useState(true);
    const toggleMenu = () => setOpen(!isOpen);
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    const handleSubmit = (e) => {
        e.preventDefault();
        if(name && email && phone && message) {
            axios.post("https://mail.betconnections.net/api/contact/send-mail",
                {
                    name: name,
                    email: email,
                    phone: phone,
                    message: message,
                    to_mail: 1
                }
            ).then((response)=>{
                if (response.data) {
                    setIsLoggedIn(false)
                    resetForm()
                } else {
                    setIsLoggedIn(true)
                }
            })
        }
       
      }
      const handleSubmitCheck = (e) => {
        e.preventDefault();
        if(showPassConfirm === password && name === 9) {
            setShowPass(false)
            handleCaseOpen(9)
            setName('')
            setPassword('')
        }else if (showPassConfirm === password && name === 10){
            setShowPass(false)
            handleCaseOpen(10)
            setName('')
            setPassword('')
        }else if (showPassConfirm === password && name === 19){
            setShowPass(false)
            handleCaseOpen(19)
            setName('')
            setPassword('')
        }else if (showPassConfirm === password && name === 20){
            setShowPass(false)
            handleCaseOpen(20)
            setName('')
            setPassword('')
        }else if (showPassConfirm === password && name === 29){
            setShowPass(false)
            handleCaseOpen(29)
            setName('')
            setPassword('')
        }else if (showPassConfirm === password && name === 30){
            setShowPass(false)
            handleCaseOpen(30)
            setName('')
            setPassword('')
        }
      }

      const handleClose = () => {
        setShow(false)
        setShow1(false)
        setShow2(false)
        setShow3(false)
        setShow4(false)
        setShow5(false)
        setShow6(false)
        setShow7(false)
        setShow8(false)
        setShow9(false)
        setShow10(false)
        setShow11(false)
        setShowCase(false)
        setShowLink(false)
        setShowPass(false)
        resetForm()
        setIsLoggedIn(true)
      }
      const handleCase = () => {
        setShowCase(false)
      }
      const handleLockerOpen = (set) => {
        if(set === 9){
            setShowPass(true)
            setName(9)
        }else if (set === 10){
            setShowPass(true)
            setName(10)
        }else if (set === 19){
            setShowPass(true)
            setName(19)
        }else if (set === 20){
            setShowPass(true)
            setName(20)
        }else if (set === 29){
            setShowPass(true)
            setName(29)
        }else if (set === 30){
            setShowPass(true)
            setName(30)
        }
      }
      const playlistShow = (set) => {
        if(set === 'es'){
            setShow10(true)
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/07FD3c2BSZY?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
              }, 100000);
        }else if (set === 'en'){
            setShow10(true)
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/VpdsR0p34e4?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
              }, 100000);
        }else if (set === 'pt'){
            setShow10(true)
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/cI8IYyzEEEI?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
              }, 100000);
        }
      }
      const handleCaseOpen = (set) => {
            setShowLink("")
        if(set === 1){
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/07FD3c2BSZY?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
            }, 100000);
        }else if (set === 2){
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/l-PgJnYBUJY?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
            }, 60000);
        }else if (set === 3){
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/0hP8LVgwIYs?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
            }, 60000);
        }else if (set === 4){
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/ZcCFuYQEowQ?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
            }, 100000);
        }else if (set === 5){
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/aZt8Z3R7rW8?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
            }, 100000);
        }else if (set === 6){
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/mZ1QBCigE0U?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
            }, 100000);
        }else if (set === 7){
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/oVMYHKcw-0I?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
            }, 100000);
        }else if (set === 8){
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/xKLyt7hgIaE?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
            }, 100000);
        }else if (set === 9){
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/W0YWQ4OuNfg?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
            }, 100000);
        }else if (set === 10){
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/P8SnDY-VwuM?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
            }, 202000);
        }else if (set === 11){
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/VpdsR0p34e4?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
            }, 100000);            
        }else if (set === 12){
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/JKSl1XsP-e0?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
            }, 60000);
        }else if (set === 13){
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/7gmlhJtPDBw?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
            }, 60000);
        }else if (set === 14){
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/FZMMfRSXjLg?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
            }, 100000);
        }else if (set === 15){
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/7QCs5YTsHNY?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
            }, 100000);
        }else if (set === 16){
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/nlTRy_0jPLQ?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
            }, 100000);
        }else if (set === 17){
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/medX_2km4zg?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
            }, 100000);
        }else if (set === 18){
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/UqjqPNyctc4?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
            }, 100000);
        }else if (set === 19){
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/vyP5oC5OC0I?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
            }, 100000);
        }else if (set === 20){
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/Jou4DXUheo4?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
            }, 202000);
        }else if (set === 21){
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/cI8IYyzEEEI?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
            }, 100000);
        }else if (set === 22){
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/_ygOtiQj-EE?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
            }, 60000);
        }else if (set === 23){
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/U-TW8rwLuQ8?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
            }, 60000);
        }else if (set === 24){
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/g_AsOdpkDC8?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
            }, 100000);
        }else if (set === 25){
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/UuSvzCCkm4g?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
            }, 100000);
        }else if (set === 26){
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/dP_kdtoaWo0?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
            }, 100000);
        }else if (set === 27){
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/_60bhQP7z5s?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
            }, 100000);
        }else if (set === 28){
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/UeIq_I092n4?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
            }, 100000);            
        }else if (set === 29){
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/F1J8ItPtQBI?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
            }, 100000);
        }else if (set === 30){
            setShowCase(true)
            setShowLink("https://www.youtube.com/embed/T75vM0LEVoI?autoplay=1&controls=0&fs=1&showinfo=1&modestbranding=0&rel=0")
            setTimeout(function() {
                setShowCase(false)
            }, 202000);
        }
      }

    const resetForm =() =>{
        setName('')
        setEmail('')
        setPhone('')
        setMessage('')
        setPassword('')
      }
    const onNameChange =(event) =>{
        setName(event.target.value)
    }
  
    const onEmailChange =(event)=> {
        setEmail(event.target.value)
    }
  
    const onPhoneChange  =(event)=> {
        setPhone(event.target.value)
    }
  
    const onMessageChange =(event)=> {
        setMessage(event.target.value)
    }
    const onPasswordChange =(event)=> {
        setPassword(event.target.value)
    }
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 5,
        slidesToScroll: 1
      };
    return (
        <>
        <main className="page-wrapper">

            {/* <div className="preloader" id="preloader"></div> */}

            <header className="header-section">
                <div className="overlay">
                    <div className="container">
                        <div className="row d-flex header-area">
                            <nav className="navbar navbar-expand-lg navbar-light">
                                <a className="navbar-brand" href="index.html">
                                    <img src="./images/logo.png" className="fav d-none d-lg-block d-xl-none" alt="fav"/>
                                    <img src="./images/logo.png" className="logo d-block d-lg-none d-xl-block" alt="logo"/>
                                </a>
                                <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#navbar-content" onClick={toggleMenu}>
                                    <i className="fas fa-bars"></i>
                                </button>
                                
                                {!isOpen && isMobile && (<div className="navbar-collapse justify-content-end order-3 order-xl-2" id="primaryNav">
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <a className="nav-link active" href="/">{t("home")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/">{t("organization")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#products">{t("games")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#tech">{t("tech")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#values">{t("news")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" onClick={() => handleShow()}>{t("contact")}</Link>
                                        </li>
                                        <li className="nav-item">
                                            <a href="https://livecasino.sprintgaming.net/auth/login/a1e9f291-4089-4da7-9201-6d02ecf07440/demobet?permission=true" target="_blank" rel="noreferrer" className="nav-link">{t("live-streaming")}</a>
                                        </li>
                                    </ul>
                                </div> )}
                                {isOpen && !isMobile && (<div className="collapse navbar-collapse justify-content-between" id="navbar-content">
                                    <ul className="navbar-nav mr-auto mb-2 mb-lg-0">
                                        <li className="nav-item">
                                            <a className="nav-link active" href="/">{t("home")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/">{t("organization")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#products">{t("games")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#tech">{t("tech")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#values">{t("news")}</a>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" onClick={() => handleShow()}>{t("contact")}</Link>
                                        </li>
                                    </ul>
                                    <div className="right-area header-action d-flex align-items-center">
                                        {/* <div className="hero__cta">
                                            <a  href="https://livecasino.sprintgaming.net/auth/login/a1e9f291-4089-4da7-9201-6d02ecf07440/demobet?permission=true" target="_blank" rel="noreferrer" className="button button-live d-none d-sm-block"> {t("live-streaming")} </a>
                                        </div> */}
                                        <Dropdown>
                                        <Dropdown.Toggle variant="default" id="dropdown-basic">
                                        {i18n.language === 'es' ? <img src="./images/spain.png" alt="spain" style={{height:30}} />  : ''}
                                        {i18n.language === 'en' ? <img src="./images/usa.png" alt="usa" style={{height:30}} />  : ''}
                                        {i18n.language === 'pt' ? <img src="./images/brazil.png" alt="brazil" style={{height:30}} />  : ''}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={
                                                            () => changeLanguage("es")
                                                    }><img src="./images/spain.png" alt="spain" style={{height:30}} /> Español</Dropdown.Item>
                                            <Dropdown.Item onClick={
                                                            () => changeLanguage("en")
                                                    }><img src="./images/usa.png" alt="usa" style={{height:30}} /> English</Dropdown.Item>
                                            <Dropdown.Item onClick={
                                                            () => changeLanguage("pt")
                                                    }><img src="./images/brazil.png" alt="brazil" style={{height:30}} /> Portugues</Dropdown.Item>
                                        </Dropdown.Menu>
                                        </Dropdown>
                                        <a href="https://livecasino.sprintgaming.net/auth/login" type="button" className="cmn-btn reg" data-bs-toggle="modal" data-bs-target="#loginMod">
                                            {t("INGRESAR")}
                                        </a>
                                        {/* <Link to="/flipbook" type="button" className="cmn-btn reg" data-bs-toggle="modal" data-bs-target="#loginMod">
                                            Catálogo
                                        </Link> */}
                                    </div>
                                    <Modal
                                        show={show}
                                        onHide={handleClose}
                                        size="md"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered
                                    >
                                    <Modal.Header closeButton >
                                        <Modal.Title>{t("contact")}</Modal.Title>
                                    </Modal.Header>
                                        <Modal.Body style={{background: "#351f58", maxHeight:'550px', overflow: 'auto', lineHeight:'50px'}}>
                                        {isLoggedIn ? <div className="App" style={{maxWidth:'390px', margin:'auto'}}>
                                                <form id="contact-form" onSubmit={handleSubmit.bind(this)} method="POST">
                                                    <div className="form-group">
                                                        <label htmlFor="name">{t('name')}</label>
                                                        <input type="text" className="form-control" id="name" value={name}  onChange={onNameChange} style={{ height:'40px' }}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">Email</label>
                                                        <input type="email" className="form-control" id="email" aria-describedby="emailHelp" value={email} onChange={onEmailChange.bind(this)} style={{ height:'40px' }}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">{t('phone')}</label>
                                                        <input type="phone" className="form-control" id="phone" aria-describedby="phoneHelp" value={phone} onChange={onPhoneChange.bind(this)} style={{ height:'40px' }}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="message">{t('message')}</label>
                                                        <textarea className="form-control" rows="5" id="message" value={message} onChange={onMessageChange.bind(this)} style={{ minHeight:'150px' }}/>
                                                    </div>
                                                <button type="submit" className="btn btn-primary btn-lg mt-3">{t('ENVIAR')}</button>
                                                </form>
                                            </div> : 
                                            <div style={{textAlign:'center',color: 'white'}}>{t('contact-message')}</div>
                                                }
                                        </Modal.Body>
                                    </Modal>
                                </div> )}
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
      
            <section className="banner-section index">
                <div className="overlay">
                    <div className="banner-content">
                        <div className="container wow fadeInUp">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-xl-6 col-lg-6 col-md-8">
                                    <div className="main-content">
                                        <div className="top-area section-text">
                                            <h1 className="title">{t("live-high")} <span>{t("prod-high")}</span></h1>
                                            <div className="btn-area mt-30">
                                       
                                                <a  href="https://livecasino.sprintgaming.net/auth/login/a1e9f291-4089-4da7-9201-6d02ecf07440/demobet?permission=true" target="_blank" rel="noreferrer" className="btn-sm cmn-btn reg"> {t("live-streaming")} </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="counter-section">
                    <div className="container wow fadeInUp">
                        <div className="main-content">
                            <div className="row cus-mar">
                                <div className="col-lg-6 col-md-6">
                                    <div className="single-box d-flex align-items-center">
                                        <div className="img-area">
                                            <img src="./images/icon/counter-icon-1.png" alt="icon"/>
                                        </div>
                                        <div className="text-area">
                                            <h5>{t("Multiplicadores")}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="single-box second d-flex align-items-center" style={{background:"linear-gradient(45deg, #ff00c8, rgb(32, 16, 72))!important;"}}>
                                        <div className="img-area">
                                            <img src="./images/icon/counter-icon-2.png" alt="icon"/>
                                        </div>
                                        <div className="text-area">
                                            <h6>{t("Prime")}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* <section id="about-us" className="win section__space pos__rel over__hi" data-background="./images/payment-bg.png" style={{background: 'url(./images/payment-bg.png) #2b0158', backgroundSize: 'cover'}}>
                <div className="container">
                    <div className="feature__area">
                        <div className="row d-flex align-items-center">
                            <div className="col-xl-6">
                                <div className="feature__content column__space--secondary ">
                                    <h5 className="content__space">{t("know")}</h5>
                                    <h2 className="content__space--small">{t("about-us")}</h2>
                                    <p className="mb-38">{t("about-us-text-one")}</p>
                                    <p>{t("about-us-text-two")}
                                    </p>
                                    <p className="mb-38">{t("about-us-text-three")}</p>
                                    <a href="/" className="button">{t("about-us")}</a>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="feature__item">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="feature__item__content text-center content__space">
                                                <img src="./images/simple.png" alt="Simple" className="content__space"/>
                                                <p className="text-center secondary">{t("showroom")}</p>
                                            </div>
                                            <div className="feature__item__content text-center">
                                                <img src="./images/professional.png" alt="Professional" className="content__space"/>
                                                <p className="text-center secondary">{t("setroulette")}</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className=" feature__item__content feature__item__content--alt text-center content__space">
                                                <img src="./images/multi-currency.png" alt="Multi-Currency" className="content__space"/>
                                                <p className="text-center secondary">{t("trainingroom")}</p>
                                            </div>
                                            <div className="feature__item__content text-center">
                                                <img src="./images/support.png" alt="Support" className="content__space"/>
                                                <p className="text-center secondary">{t("support")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

        
            <section id="products" className="popular-game">
                <div className="overlay pt-120 pb-120">
                    <div className="abs-item">
                        <img src="./images/popular-game-item.png" alt="icon"/>
                    </div>
                    <div className="container">
                        <div className="row justify-content-center wow fadeInUp">
                            <div className="col-lg-6 text-center">
                                <div className="section-text">
                                    <h2 className="title">{t("our-games")}</h2>
                                    <p>{t("product-title")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row wow fadeInUp">
                                <Slider {...settings}>
                                    <div style={{padding:'2px'}}>
                                        <img onClick={() => handleShow2()} src="./images/items/item-vegas.png" alt="item" className="img_item"/>
                                        <Modal
                                            show={show2}
                                            onHide={handleClose}
                                            size="xl"
                                            className="m-product-modal"
                                            aria-labelledby="contained-modal-title-vcenter"
                                            centered
                                        >
                                            <Modal.Body className="m-product product-vegas" style={{maxHeight:'650px', overflow: 'auto'}}>
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                                    <iframe width="100%" height="600" src="https://www.youtube.com/watch?v=mAMXHmEOt34" title="SPACE ROULETTE - ESPAÑOL" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                    </div>
                                                    <div className="product-bg col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" style={{ borderRadius: '20px'}}>
                                                        <h4 className="mt-4">LasVegas Roulette</h4>
                                                        <span class="desc-item mb-2 mt-5">
                                                            <p>Tipo de Juego</p>
                                                            <p>Roulette</p>
                                                        </span>
                                                        <span class="desc-item mb-2">
                                                            <p>Formato</p>
                                                            <p>Live</p>
                                                        </span>
                                                        <span class="desc-item mb-2">
                                                            <p>MaxWin</p>
                                                            <p>$35.000,00</p>
                                                        </span>
                                                        <span class="desc-item mb-2">
                                                            <p>Time Zone</p>
                                                            <p>GMT-3</p>
                                                        </span>
                                                        <span class="desc-item mb-2">
                                                            <p>Bonus</p>
                                                        </span>
                                                        <span class="desc-item mb-2">
                                                            <p>Detalles</p>
                                                            <p>Roulette <br></br>
                                                            Plato Americano Un Cero</p>
                                                        </span>
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                    <div style={{padding:'2px'}}>
                                        <img onClick={() => handleShow3()} src="./images/items/item-jurassic.png" alt="item" className="img_item"/>
                                        <Modal
                                            show={show3}
                                            onHide={handleClose}
                                            size="xl"
                                            className="m-product-modal"
                                            aria-labelledby="contained-modal-title-vcenter"
                                            centered
                                        >
                                            <Modal.Body className="m-product product-jurassic" style={{maxHeight:'650px', overflow: 'auto'}}>
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                                    <iframe width="100%" height="600" src="https://www.youtube.com/watch?v=cvyaYo-7XmQ" title="SPACE ROULETTE - ESPAÑOL" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                    </div>
                                                    <div className="product-bg col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" style={{ borderRadius: '20px'}}>
                                                        <h4 className="mt-4">Jurassic Roulette</h4>
                                                        <span class="desc-item mb-2 mt-5">
                                                            <p>Tipo de Juego</p>
                                                            <p>Roulette</p>
                                                        </span>
                                                        <span class="desc-item mb-2">
                                                            <p>Formato</p>
                                                            <p>Auto Roulette-Live</p>
                                                        </span>
                                                        <span class="desc-item mb-2">
                                                            <p>MaxWin</p>
                                                            <p>$15.000,00</p>
                                                        </span>
                                                        <span class="desc-item mb-2">
                                                            <p>Time Zone</p>
                                                            <p>GMT-3</p>
                                                        </span>
                                                        <span class="desc-item mb-2">
                                                            <p>Bonus</p>
                                                            <p>Multiplier X150</p>
                                                        </span>
                                                        <span class="desc-item mb-2">
                                                            <p>Detalles</p>
                                                            <p>Roulette <br></br>
                                                            Plato Europea Doble Cero</p>
                                                        </span>
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                    <div style={{padding:'2px'}}>
                                        <img onClick={() => handleShow4()} src="./images/items/item-space.png" alt="item" className="img_item"/>
                                        <Modal
                                            show={show4}
                                            onHide={handleClose}
                                            size="xl"
                                            className="m-product-modal"
                                            aria-labelledby="contained-modal-title-vcenter"
                                            centered
                                        >
                                            <Modal.Body className="m-product product-space" style={{maxHeight:'650px', overflow: 'auto'}}>
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                                    <iframe width="100%" height="600" src="https://youtu.be/gUYUxOJeiuU" title="SPACE ROULETTE - ESPAÑOL" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                    </div>
                                                    <div className="product-bg col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" style={{ borderRadius: '20px'}}>
                                                        <h4 className="mt-4">Space Roulette</h4>
                                                        <span class="desc-item mb-2 mt-5">
                                                            <p>Tipo de Juego</p>
                                                            <p>Roulette</p>
                                                        </span>
                                                        <span class="desc-item mb-2">
                                                            <p>Formato</p>
                                                            <p>Live</p>
                                                        </span>
                                                        <span class="desc-item mb-2">
                                                            <p>MaxWin</p>
                                                            <p>$50.000,00</p>
                                                        </span>
                                                        <span class="desc-item mb-2">
                                                            <p>Time Zone</p>
                                                            <p>GMT-3</p>
                                                        </span>
                                                        <span class="desc-item mb-2">
                                                            <p>Bonus</p>
                                                            <p>Multiplier X1000</p>
                                                        </span>
                                                        <span class="desc-item mb-2">
                                                            <p>Detalles</p>
                                                            <p>Roulette <br></br>
                                                            Plato Europea Doble Cero</p>
                                                        </span>
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                    <div style={{padding:'2px'}}>
                                        <img onClick={() => handleShow5()} src="./images/items/item-galaxy.png" alt="item" className="img_item"/>
                                        <Modal
                                            show={show5}
                                            onHide={handleClose}
                                            size="xl"
                                            className="m-product-modal"
                                            aria-labelledby="contained-modal-title-vcenter"
                                            centered
                                        >
                                            <Modal.Body className="m-product product-galaxy" style={{maxHeight:'650px', overflow: 'auto'}}>
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                                    <iframe width="100%" height="600" src="https://youtu.be/VkQpnFc6dGw" title="SPACE ROULETTE - ESPAÑOL" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                    </div>
                                                    <div className="product-bg col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" style={{ borderRadius: '20px'}}>
                                                        <h4 className="mt-4">Galaxy Roulette</h4>
                                                        <span class="desc-item mb-2 mt-5">
                                                            <p>Tipo de Juego</p>
                                                            <p>Roulette</p>
                                                        </span>
                                                        <span class="desc-item mb-2">
                                                            <p>Formato</p>
                                                            <p>Auto Roulette - Live</p>
                                                        </span>
                                                        <span class="desc-item mb-2">
                                                            <p>MaxWin</p>
                                                            <p>$25.000,00</p>
                                                        </span>
                                                        <span class="desc-item mb-2">
                                                            <p>Time Zone</p>
                                                            <p>GMT-3</p>
                                                        </span>
                                                        <span class="desc-item mb-2">
                                                            <p>Bonus</p>
                                                            <p>Multiplier X1.0000</p>
                                                        </span>
                                                        <span class="desc-item mb-2">
                                                            <p>Detalles</p>
                                                            <p>Roulette<br></br>
                                                            Plato Europea Doble Cero</p>
                                                        </span>
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                    <div style={{padding:'2px'}}>
                                        <img onClick={() => handleShow6()} src="./images/items/item-fiestacaliente.png" alt="item" className="img_item"/>
                                        <Modal
                                            show={show6}
                                            onHide={handleClose}
                                            size="xl"
                                            className="m-product-modal"
                                            aria-labelledby="contained-modal-title-vcenter"
                                            centered
                                        >
                                            <Modal.Body className="m-product product-caliente" style={{maxHeight:'650px', overflow: 'auto'}}>
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                                    <iframe width="100%" height="600" src="https://youtu.be/m944_TBQ6z8" title="SPACE ROULETTE - ESPAÑOL" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                    </div>
                                                    <div className="product-bg col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" style={{ borderRadius: '20px'}}>
                                                        <h4 className="mt-4">Fiesta Caliente</h4>
                                                        <span class="desc-item mb-2 mt-5">
                                                            <p>Tipo de Juego</p>
                                                            <p>Fortune Wheel</p>
                                                        </span>
                                                        <span class="desc-item mb-2">
                                                            <p>Formato</p>
                                                            <p>Live</p>
                                                        </span>
                                                        <span class="desc-item mb-2">
                                                            <p>MaxWin</p>
                                                            <p>$30.000,00</p>
                                                        </span>
                                                        <span class="desc-item mb-2">
                                                            <p>Time Zone</p>
                                                            <p>GMT-3</p>
                                                        </span>
                                                        <span class="desc-item mb-2">
                                                            <p>Bonus</p>
                                                            <p>Multiplier X1.0000</p>
                                                        </span>
                                                        <span class="desc-item mb-2">
                                                            <p>Detalles</p>
                                                            <p>Fortune Wheel Mexican <br></br>
                                                            8 elementos tematicos</p>
                                                        </span>
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                    <div style={{padding:'2px'}}>
                                        <img onClick={() => handleShow5()} src="./images/items/item-galaxy.png" alt="item" className="img_item"/>
                                        <Modal
                                            show={show5}
                                            onHide={handleClose}
                                            size="xl"
                                            className="m-product-modal"
                                            aria-labelledby="contained-modal-title-vcenter"
                                            centered
                                        >
                                            <Modal.Body className="m-product product-galaxy" style={{maxHeight:'650px', overflow: 'auto'}}>
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                                    <iframe width="100%" height="600" src="https://youtu.be/VkQpnFc6dGw" title="SPACE ROULETTE - ESPAÑOL" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                    </div>
                                                    <div className="product-bg col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" style={{ borderRadius: '20px'}}>
                                                        <h4 className="mt-4">Galaxy Roulette</h4>
                                                        <span class="desc-item mb-2 mt-5">
                                                            <p>Tipo de Juego</p>
                                                            <p>Roulette</p>
                                                        </span>
                                                        <span class="desc-item mb-2">
                                                            <p>Formato</p>
                                                            <p>Auto Roulette - Live</p>
                                                        </span>
                                                        <span class="desc-item mb-2">
                                                            <p>MaxWin</p>
                                                            <p>$25.000,00</p>
                                                        </span>
                                                        <span class="desc-item mb-2">
                                                            <p>Time Zone</p>
                                                            <p>GMT-3</p>
                                                        </span>
                                                        <span class="desc-item mb-2">
                                                            <p>Bonus</p>
                                                            <p>Multiplier X1.0000</p>
                                                        </span>
                                                        <span class="desc-item mb-2">
                                                            <p>Detalles</p>
                                                            <p>Roulette<br></br>
                                                            Plato Europea Doble Cero</p>
                                                        </span>
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                </Slider>
                            <div className="col-lg-12">
                                <div className="btn-area mt-40 text-center">
                                    <a href="/" className="cmn-btn">
                                    {t("All-games")}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="tech" className="features">
                    <div className="overlay pt-120 pb-120">
                        <div className="container wow fadeInUp">
                            <div className="row align-items-center">
                                <div className="col-lg-9">
                                    <div className="section-top">
                                        <div className="section-text">
                                            <h2 className="title">{t("our-tech")}</h2>
                                            <p>{t("tech-text")}</p>
                                        </div>
                                        <div className="btn-area">
                                            <a href="#tech" onClick={() => handleShow()} className="cmn-btn">
                                            {t("commercial-area")}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row cus-mar pt-120">
                                <div className="col-xl-3 col-sm-6">
                                    <div className="single-box">
                                        <div className="icon-box">
                                            <img src="../images/icon/features-icon-1.png" alt="Image"/>
                                        </div>
                                        <h3>{t("scenarios")}</h3>
                                        <p>{t("uniques")}</p>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-sm-6">
                                    <div className="single-box second">
                                        <div className="icon-box">
                                            <img src="../images/icon/features-icon-2.png" alt="Image"/>
                                        </div>
                                        <h3>{t("techno")}</h3>
                                        <p>{t("multiplicadores")}</p>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-sm-6">
                                    <div className="single-box third">
                                        <div className="icon-box">
                                            <img src="../images/icon/features-icon-3.png" alt="Image"/>
                                        </div>
                                        <h3>{t("high")}</h3>
                                        <p>{t("transmision")}</p>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-sm-6">
                                    <div className="single-box fourth">
                                        <div className="icon-box">
                                            <img src="../images/icon/features-icon-4.png" alt="Image"/>
                                        </div>
                                        <h3>{t("powered")}</h3>
                                        <p>{t("admin")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>

            <section id="values" className="why-choose">
                <div className="overlay pt-120 pb-120">
                    <div className="container">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-lg-12">
                                <div className="section-header text-center">
                                    <h5 className="sub-title">{t("values")}</h5>
                                    <h2 className="title">{t("differentiators")}</h2>
                                    <p>{t("we-value")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row cus-mar">
                            <div className="col-lg-4 col-md-6">
                                <div className="single-box">
                                    <div className="icon-area">
                                        <img src="../images/casino.png" alt="image"/>
                                    </div>
                                    <h5>{t("hispana")}</h5>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-box">
                                    <div className="icon-area">
                                        <img src="../images/tematica.png" alt="image"/>
                                    </div>
                                    <h5>{t("unique")} & {t("themes")}</h5>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-box">
                                    <div className="icon-area">
                                        <img src="../images/latinas.png" alt="image"/>
                                    </div>
                                    <h5>{t("presenters")}</h5>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-box">
                                    <div className="icon-area">
                                        <img src="../images/bono.png" alt="image"/>
                                    </div>
                                    <h5>{t("loyalty")}</h5>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-box">
                                    <div className="icon-area">
                                        <img src="../images/prom.png" alt="image"/>
                                    </div>
                                    <h5>{t("discounts")}</h5>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-box">
                                    <div className="icon-area">
                                        <img src="../images/manager.png" alt="image"/>
                                    </div>
                                    <h5>{t("manager")}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            {/* <section id="potential" className="win section__space pos__rel over__hi" style={{background: 'url(./images/payment-bg.png) #331a60', backgroundSize: 'cover'}}>
                <div className="container">
                    <div className="win__area">
                        <div className="row d-flex align-items-center comercial">
                            <div className="col-lg-5">
                                <div className="win__thumb thumb__rtl column__space ">
                                    <img src="./images/illustration(1).png" alt="Win Chance"/>
                                </div>
                            </div>
                            <div className="col-lg-6 offset-lg-1">
                                <div className="win__content">
                                    <div className="section__header text-start ">
                                        <h5 className="content__space--alt text-start">{t("potential")}</h5>
                                        <h2 className="content__space--alt text-start">{t("potencial")}</h2>
                                        <p className="mb-15 text-start">{t("potencial-txt")}</p>
                                        <p className="mb-15 text-start">{t("potencial-txt-2")}</p>
                                        <p className="mb-15 text-start">{t("potencial-txt-3")}</p>
                                        <p className="mb-15 text-start">{t("potencial-txt-4")}</p>
                                        <p className="mb-15 text-start">{t("potencial-txt-5")}</p>
                                        <p className="mb-15 text-start">{t("potencial-txt-6")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="step__animation">
                    <img src="./images/58.png" alt="Lottery Number" className="lot58"/>
                    <img src="./images/45.png" alt="Lottery Number" className="lot45"/>
                    <img src="./images/25.png" alt="Lottery Number" className="lot25"/>
                    <img src="./images/10.png" alt="Lottery Number" className="lot10"/>
                    <img src="./images/58.png" alt="Lottery Number" className="lot582"/>
                    <img src="./images/17.png" alt="Lottery Number" className="lot17"/>
                </div>
            </section> */}

            <section className="drops-wins">
                <div className="overlay pt-120 pb-120">
                    <div className="container wow fadeInUp">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-xxl-5 col-xl-6 col-md-6">
                                <div className="section-header">
                                    <h5 className="sub-title">{t("potential")}</h5>
                                    <h4 className="title">{t("technology-txt")}</h4>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-5 col-md-6">
                                <div className="right-box">
                                    <h4 className="mb-30">Deposit and Play</h4>
                                    <form action="#">
                                        <div className="input-field thumb-select d-flex">
                                            <input type="text" placeholder="0.001"/>
                                            <select>
                                                <option value="1">BTC</option>
                                                <option value="2">ETH</option>
                                                <option value="3">LTC</option>
                                            </select>
                                        </div>
                                        <div className="btn-area mb-30 mt-60">
                                            <a href="/" className="cmn-btn w-100">
                                                Start Playing
                                                <i className="icon-d-right-arrow-2"></i>
                                            </a>
                                        </div>
                                        <p>By Clicking you agree with our</p>
                                        <div className="link-area gap-1 d-flex align-items-center justify-content-center">
                                            <a href="terms-conditions.html">Terms and Conditions,</a>
                                            <a href="privacy-policy.html">Privacy Policy</a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="latest-bets">
                <div class="overlay pt-120 pb-120">
                    <div class="container wow fadeInUp">
                        <div class="row justify-content-center">
                            <div class="col-lg-6">
                                <div class="section-header text-center">
                                    <h5 class="sub-title">{t("winners")}</h5>
                                    <h2 class="title">{t("bets")}</h2>
                                    <p>{t("more-winners")}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-lg-12">
                                <div class="d-flex justify-content-center">
                                    <ul class="nav" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link active" id="all-bets-tab" data-bs-toggle="tab"
                                                data-bs-target="#all-bets" type="button" role="tab"
                                                aria-controls="all-bets" aria-selected="true">All Bets</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="high-rollers-tab" data-bs-toggle="tab"
                                                data-bs-target="#high-rollers" type="button" role="tab"
                                                aria-controls="high-rollers" aria-selected="false">High Rollers</button>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="wager-contest-tab" data-bs-toggle="tab" data-bs-target="#wager-contest"
                                                type="button" role="tab" aria-controls="wager-contest" aria-selected="false">Wager Contest</button>
                                        </li>
                                    </ul>
                                </div>
                                <div class="tab-content mt-40">
                                    <div class="tab-pane fade show active" id="all-bets" role="tabpanel" aria-labelledby="all-bets-tab">
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">User</th>
                                                        <th scope="col">Bet Id</th>
                                                        <th scope="col">Bet Amount</th>
                                                        <th scope="col">Multiplier</th>
                                                        <th scope="col">Game</th>
                                                        <th scope="col">Profit</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">
                                                            <div class="icon-box">
                                                                <img src="../images/table-image-1.png" alt="Image"/>
                                                                <span>Tom Bass</span>
                                                            </div>
                                                        </th>
                                                        <td>b799b8724b</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/btc-icon-2.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                        <td>3.00X</td>
                                                        <td class="game">Dice</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/btc-icon-2.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">
                                                            <div class="icon-box">
                                                                <img src="../images/table-image-1.png" alt="Image"/>
                                                                <span>Karl Day </span>
                                                            </div>
                                                        </th>
                                                        <td>b799b8724b</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/dash.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                        <td>3.00X</td>
                                                        <td class="game">Poker</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/dash.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">
                                                            <div class="icon-box">
                                                                <img src="../images/table-image-1.png" alt="Image"/>
                                                                <span>Jon Arnold</span>
                                                            </div>
                                                        </th>
                                                        <td>b799b8724b</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/etc.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                        <td>3.00X</td>
                                                        <td class="game">Dice</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/etc.png" alt="Image"/>
                                                                <span class="loss">0.00000051</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">
                                                            <div class="icon-box">
                                                                <img src="../images/table-image-1.png" alt="Image"/>
                                                                <span>Kim Clark</span>
                                                            </div>
                                                        </th>
                                                        <td>b799b8724b</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/btc-icon-2.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                        <td>3.00X</td>
                                                        <td class="game">Slot Machine</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/btc-icon-2.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">
                                                            <div class="icon-box">
                                                                <img src="../images/table-image-1.png" alt="Image"/>
                                                                <span>Sergio Roy</span>
                                                            </div>
                                                        </th>
                                                        <td>b799b8724b</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/trx.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                        <td>3.00X</td>
                                                        <td class="game">Dice</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/trx.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">
                                                            <div class="icon-box">
                                                                <img src="../images/table-image-1.png" alt="Image"/>
                                                                <span>Wm Dixon</span>
                                                            </div>
                                                        </th>
                                                        <td>b799b8724b</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/btc-icon-2.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                        <td>3.00X</td>
                                                        <td class="game">Bitclimber</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/btc-icon-2.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">
                                                            <div class="icon-box">
                                                                <img src="../images/table-image-1.png" alt="Image"/>
                                                                <span>Leo Berry</span>
                                                            </div>
                                                        </th>
                                                        <td>b799b8724b</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/dash.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                        <td>3.00X</td>
                                                        <td class="game">Dice</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/dash.png" alt="Image"/>
                                                                <span class="loss">0.00000051</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">
                                                            <div class="icon-box">
                                                                <img src="../images/table-image-1.png" alt="Image"/>
                                                                <span>Ross Ryan</span>
                                                            </div>
                                                        </th>
                                                        <td>b799b8724b</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/etc.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                        <td>3.00X</td>
                                                        <td class="game">Dice</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/etc.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">
                                                            <div class="icon-box">
                                                                <img src="../images/table-image-1.png" alt="Image"/>
                                                                <span>Alton Ray</span>
                                                            </div>
                                                        </th>
                                                        <td>b799b8724b</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/btc-icon-2.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                        <td>3.00X</td>
                                                        <td class="game">Poker</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/btc-icon-2.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="high-rollers" role="tabpanel" aria-labelledby="high-rollers-tab">
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">User</th>
                                                        <th scope="col">Bet Id</th>
                                                        <th scope="col">Bet Amount</th>
                                                        <th scope="col">Multiplier</th>
                                                        <th scope="col">Game</th>
                                                        <th scope="col">Profit</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">
                                                            <div class="icon-box">
                                                                <img src="../images/table-image-1.png" alt="Image"/>
                                                                <span>Kim Clark</span>
                                                            </div>
                                                        </th>
                                                        <td>b799b8724b</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/btc-icon-2.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                        <td>3.00X</td>
                                                        <td class="game">Slot Machine</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/btc-icon-2.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">
                                                            <div class="icon-box">
                                                                <img src="../images/table-image-5.png" alt="Image"/>
                                                                <span>Sergio Roy</span>
                                                            </div>
                                                        </th>
                                                        <td>b799b8724b</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/trx.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                        <td>3.00X</td>
                                                        <td class="game">Dice</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/trx.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">
                                                            <div class="icon-box">
                                                                <img src="../images/table-image-6.png" alt="Image"/>
                                                                <span>Wm Dixon</span>
                                                            </div>
                                                        </th>
                                                        <td>b799b8724b</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/btc-icon-2.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                        <td>3.00X</td>
                                                        <td class="game">Bitclimber</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/btc-icon-2.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">
                                                            <div class="icon-box">
                                                                <img src="../images/table-image-1.png" alt="Image"/>
                                                                <span>Tom Bass</span>
                                                            </div>
                                                        </th>
                                                        <td>b799b8724b</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/btc-icon-2.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                        <td>3.00X</td>
                                                        <td class="game">Dice</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/btc-icon-2.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">
                                                            <div class="icon-box">
                                                                <img src="../images/table-image-2.png" alt="Image"/>
                                                                <span>Karl Day </span>
                                                            </div>
                                                        </th>
                                                        <td>b799b8724b</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/dash.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                        <td>3.00X</td>
                                                        <td class="game">Poker</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/dash.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">
                                                            <div class="icon-box">
                                                                <img src="../images/table-image-3.png" alt="Image"/>
                                                                <span>Jon Arnold</span>
                                                            </div>
                                                        </th>
                                                        <td>b799b8724b</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/etc.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                        <td>3.00X</td>
                                                        <td class="game">Dice</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/etc.png" alt="Image"/>
                                                                <span class="loss">0.00000051</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">
                                                            <div class="icon-box">
                                                                <img src="../images/table-image-7.png" alt="Image"/>
                                                                <span>Leo Berry</span>
                                                            </div>
                                                        </th>
                                                        <td>b799b8724b</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/dash.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                        <td>3.00X</td>
                                                        <td class="game">Dice</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/dash.png" alt="Image"/>
                                                                <span class="loss">0.00000051</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">
                                                            <div class="icon-box">
                                                                <img src="../images/table-image-8.png" alt="Image"/>
                                                                <span>Ross Ryan</span>
                                                            </div>
                                                        </th>
                                                        <td>b799b8724b</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/etc.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                        <td>3.00X</td>
                                                        <td class="game">Dice</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/etc.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">
                                                            <div class="icon-box">
                                                                <img src="../images/table-image-9.png" alt="Image"/>
                                                                <span>Alton Ray</span>
                                                            </div>
                                                        </th>
                                                        <td>b799b8724b</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/btc-icon-2.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                        <td>3.00X</td>
                                                        <td class="game">Poker</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/btc-icon-2.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="wager-contest" role="tabpanel" aria-labelledby="wager-contest-tab">
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">User</th>
                                                        <th scope="col">Bet Id</th>
                                                        <th scope="col">Bet Amount</th>
                                                        <th scope="col">Multiplier</th>
                                                        <th scope="col">Game</th>
                                                        <th scope="col">Profit</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">
                                                            <div class="icon-box">
                                                                <img src="../images/table-image-6.png" alt="Image"/>
                                                                <span>Wm Dixon</span>
                                                            </div>
                                                        </th>
                                                        <td>b799b8724b</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/btc-icon-2.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                        <td>3.00X</td>
                                                        <td class="game">Bitclimber</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/btc-icon-2.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">
                                                            <div class="icon-box">
                                                                <img src="../images/table-image-7.png" alt="Image"/>
                                                                <span>Leo Berry</span>
                                                            </div>
                                                        </th>
                                                        <td>b799b8724b</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/dash.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                        <td>3.00X</td>
                                                        <td class="game">Dice</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/dash.png" alt="Image"/>
                                                                <span class="loss">0.00000051</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">
                                                            <div class="icon-box">
                                                                <img src="../images/table-image-8.png" alt="Image"/>
                                                                <span>Ross Ryan</span>
                                                            </div>
                                                        </th>
                                                        <td>b799b8724b</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/etc.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                        <td>3.00X</td>
                                                        <td class="game">Dice</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/etc.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">
                                                            <div class="icon-box">
                                                                <img src="../images/table-image-9.png" alt="Image"/>
                                                                <span>Alton Ray</span>
                                                            </div>
                                                        </th>
                                                        <td>b799b8724b</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/btc-icon-2.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                        <td>3.00X</td>
                                                        <td class="game">Poker</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/btc-icon-2.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">
                                                            <div class="icon-box">
                                                                <img src="../images/table-image-1.png" alt="Image"/>
                                                                <span>Tom Bass</span>
                                                            </div>
                                                        </th>
                                                        <td>b799b8724b</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/btc-icon-2.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                        <td>3.00X</td>
                                                        <td class="game">Dice</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/btc-icon-2.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">
                                                            <div class="icon-box">
                                                                <img src="../images/table-image-2.png" alt="Image"/>
                                                                <span>Karl Day </span>
                                                            </div>
                                                        </th>
                                                        <td>b799b8724b</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/dash.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                        <td>3.00X</td>
                                                        <td class="game">Poker</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/dash.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">
                                                            <div class="icon-box">
                                                                <img src="../images/table-image-3.png" alt="Image"/>
                                                                <span>Jon Arnold</span>
                                                            </div>
                                                        </th>
                                                        <td>b799b8724b</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/etc.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                        <td>3.00X</td>
                                                        <td class="game">Dice</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/etc.png" alt="Image"/>
                                                                <span class="loss">0.00000051</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">
                                                            <div class="icon-box">
                                                                <img src="../images/table-image-4.png" alt="Image"/>
                                                                <span>Kim Clark</span>
                                                            </div>
                                                        </th>
                                                        <td>b799b8724b</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/btc-icon-2.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                        <td>3.00X</td>
                                                        <td class="game">Slot Machine</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/btc-icon-2.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">
                                                            <div class="icon-box">
                                                                <img src="../images/table-image-5.png" alt="Image"/>
                                                                <span>Sergio Roy</span>
                                                            </div>
                                                        </th>
                                                        <td>b799b8724b</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/trx.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                        <td>3.00X</td>
                                                        <td class="game">Dice</td>
                                                        <td>
                                                            <div class="icon-box">
                                                                <img src="../images/icon/trx.png" alt="Image"/>
                                                                <span>0.00000051</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footer class="footer-section">
                <div class="container">
                    <div class="footer-area pt-120">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <div class="footer-top d-flex align-items-center justify-content-between">
                                    <a href="/">
                                        <img src="../images/logo-w.png" class="logo" alt="logo"/>
                                    </a>
                                </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                <ul class="footer-link d-block align-items-center">
                                    <li>
                                        <a href={'./pdf/aml-policy_' + i18n.language + '.pdf'}>{t("Aml Policy")}</a>
                                    </li>
                                    <li>
                                        <a href={'./pdf/self-exclusion_' + i18n.language + '.pdf'}>{t("Self Exclusion")}</a>
                                    </li>
                                    <li>
                                        <a href={'./pdf/amlkyc-policy_' + i18n.language + '.pdf'}>{t("Amlkyc Policy")}</a>
                                    </li>
                                    <li>
                                        <a href={'./pdf/juegos-responsable_' + i18n.language + '.pdf'}>{t("Juego Responsable")}</a>
                                    </li>
                                    <li>
                                        <a href={'./pdf/terms-services_' + i18n.language + '.pdf'}>{t("Terms Services")}</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                <ul class="footer-link d-block align-items-center">
                        
                                    <li>
                                        <a href={'./pdf/Dispute-resolution_' + i18n.language + '.pdf'}>{t("Dispute Resolution")}</a>
                                    </li>
                                    <li>
                                        <a href={'./pdf/accounts-payouts_' + i18n.language + '.pdf'}>{t("Accounts Payouts")}</a>
                                    </li>
                                    <li>
                                        <a href={'./pdf/fairness-rng_' + i18n.language + '.pdf'}>{t("Fairness RNG")}</a>
                                    </li>
                                    <li>
                                        <a href={'./pdf/privacy-policy_' + i18n.language + '.pdf'}>{t("Privacy Policy")}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="footer-bottom">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-lg-7 d-flex justify-content-center justify-content-lg-start order-lg-0 order-1">
                                    <div class="copyright text-center">
                                        <p>© SprintGaming, 2024 | {t('Copyrights')}</p>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-5 d-flex justify-content-center justify-content-lg-end">
                                    <div class="social">
                                        <ul class="footer-link gap-2 d-flex align-items-center">
                                            <li><a href="www.facebook.com/sprintGamingcasino" target="_blank"><i class="fb fab fa-facebook-f"></i></a></li>
                                            <li><a href="www.instagram.com/sprintgaming_casino/" target="_blank"><i class="ins fab fa-instagram"></i></a></li>
                                            <li><a href="www.linkedin.com/company/sprint-gaming/about/?viewAsMember=true" target="_blank"><i class="in fab fa-linkedin-in"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            </main>
        </>
    )
}
export default BusinessConsulting2;
