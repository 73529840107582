import React from 'react';
import {useTranslation} from "react-i18next";

const Flipbook = () => {
    const {t, i18n} = useTranslation();
    return (
        <> 
            {i18n.language === "es"  &&
                (<iframe src="https://player.flipsnack.com?hash=Q0ZCN0E5RUVGQjUremhtd2ttMzFjaQ==" width="100%" height="1080" seamless="seamless" scrolling="no" frameBorder="0" allowFullScreen allow="autoplay; clipboard-read; clipboard-write"></iframe> )
            }
            {i18n.language === "en" &&
                (<iframe src="https://player.flipsnack.com?hash=Q0ZCN0E5RUVGQjUrdG0weHlhbTFjaQ==" width="100%" height="1080" seamless="seamless" scrolling="no" frameBorder="0" allowFullScreen allow="autoplay; clipboard-read; clipboard-write"></iframe>)
            }
            {i18n.language === "pt" && 
                (<iframe src="https://player.flipsnack.com?hash=QkJBNTk5NjZBRUQremg1b2xpY3J6cA==" width="100%" height="1080" seamless="seamless" scrolling="no" frameBorder="0" allowFullScreen allow="autoplay; clipboard-read; clipboard-write"></iframe>)
            }
           

           

           
        </>
    )
}
export default Flipbook;
